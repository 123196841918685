<template>
  <div class="talentsData">
    <div class="bottomCard ly-container__bg">
      <div class="ly_operation" style="margin-bottom: 10px">
        <div class="left">
          <!-- 添加案例 -->
          <!-- <el-button @click="addItem" type="primary" icon="el-icon-plus"
            >添加资讯来源</el-button
          > -->
          <!-- <div class="upload_time">
            <div class="caretTime" @click="uploadTime" style="color: #606266">
              按上传时间
            </div>
            <i
              class="el-icon-caret-top"
              :class="[queryInfo.order == 1 ? 'iconColor' : 'isActiveTime1']"
              @click="caretTop"
            />
            <i
              class="el-icon-caret-bottom"
              :class="[queryInfo.order == 0 ? 'iconColor' : 'isActiveTime1']"
              @click="caretBottom"
            />
          </div> -->
        </div>
        <!-- 搜索框 -->
        <el-input
          placeholder="请输入资讯来源名称"
          v-model="queryInfo.queryConditions"
          style="width: 470px"
          @change="getSchemes1"
        >
          <template slot="append">
            <div @click="getSchemes1" style="cursor: pointer">搜索</div>
          </template>
        </el-input>
      </div>
      <!-- 表格数据 -->
      <el-table
        class="ly_table"
        :data="dataData"
        :cell-style="{ 'text-align': 'center' }"
        @sort-change="sortChange"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="序号" align="center" width="80">
          <template v-slot="scope">
            <span>{{
              (queryInfo.pageNum - 1) * queryInfo.pageSize + (scope.$index + 1)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="siteName" label="网站名称">
          <template v-slot="{ row }">
            <div @click="editTalent(row)" style="cursor: pointer">
              <span>{{ row.siteName }}</span>
            </div>
          </template>
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column
          prop="level"
          label="资讯等级"
          align="center"
          sortable="custom"
        ></el-table-column>
        <el-table-column
          prop="recruitmentCount"
          label="资讯个数"
          align="center"
          sortable="custom"
        >
          <template v-slot="{ row }">
            <div
              @click="recruitmentCount(row)"
              style="cursor: pointer; color: #1e9fff"
            >
              <span>{{ row.recruitmentCount }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="todayAddNum"
          label="今日新增个数"
          align="center"
          sortable="custom"
        >
          <template v-slot="{ row }">
            <div
              @click="todayAddNum(row)"
              style="cursor: pointer; color: #1e9fff"
            >
              <span>{{ row.todayAddNum }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="outputNum"
          label="周资讯数量"
          align="center"
          sortable="custom"
        >
          <template v-slot="{ row }">
            <div
              @click="weekNumber(row)"
              style="cursor: pointer; color: #1e9fff"
            >
              <span>{{ row.outputNum }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="resourceDescribe"
          label="资讯来源描述"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <button-table
              @buttonClick="editTalent(scope.row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteTalent(scope.row.id)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :current-page="queryInfo.pageNumber"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script src="../../assets/font/iconfont.js"></script>
<script>
import DicTagButton from "@/components/DicTagButton.vue";
import UploadFileTalent from "@/components/UploadFileTalent.vue";
import ButtonTable from "../../../components/ButtonTable.vue";
import {
  listRecruitmentResourcePage,
  newsSourceDeleteById,
} from "@/api/news.js";
import DicRadioButton from "@/components/DicRadioButton.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
export default {
  components: {
    ButtonTable,
    DicRadioButton,
    DicTagButton,
    DicCheckboxGroup,
    UploadFileTalent,
  },
  created() {
    this.getSchemes();
  },
  data() {
    return {
      // 查询搜索数据
      // 表格数据
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
        order: 1,
        columnName: "update_time",
      },
      dataData: [],
      total: 0,
      // 搜索
      input2: "",
      // 删除
      deleteTalentData: [],
      multipleSelection: [],
    };
  },
  methods: {
    // 按上传时间显示
    async uploadTime() {
      if (this.queryInfo.order == 1) {
        this.queryInfo.order = 0;
        this.getSchemes();
      } else {
        this.queryInfo.order = 1;
        this.getSchemes();
      }
    },
    // 浏览人才数据
    getSchemes1() {
      let queryConditions = this.queryInfo.queryConditions;
      this.queryInfo = {
        queryConditions: queryConditions,
        pageSize: 10,
        pageNum: 1,
        order: 1,
      };
      this.getSchemes();
    },
    async getSchemes() {
      const res = await listRecruitmentResourcePage(this.queryInfo);
      if (res.code === 200) {
        this.dataData = res.data.list;
        this.total = res.data.total;
      }
    },
    // 按姓名搜索
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getSchemes();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getSchemes();
    },
    // 选中表格数据并赋值id选项
    handleSelectionChange(val) {
      this.multipleSelection = val.map((item) => {
        return item.id;
      });
    },
    // 跳转编辑页面
    editTalent(data) {
      let text = this.$router.resolve({
        path: "/news/newsInformation/sourceInfo",
        query: {
          newsData: data.id,
          personType: 1,
        },
      });
      window.open(text.href, "_blank");
    },
    recruitmentCount(data) {
      let text = this.$router.resolve({
        path: "/news/newsInformation/recruitmentCount",
        query: {
          newsData: data.siteName,
          newsDataId: data.id,
          personType: 1,
        },
      });
      window.open(text.href, "_blank");
    },
    todayAddNum(data) {
      let text = this.$router.resolve({
        path: "/news/newsInformation/todayAddNum",
        query: {
          newsData: data.siteName,
          newsDataId: data.id,
          personType: 1,
        },
      });
      window.open(text.href, "_blank");
    },
    weekNumber(data) {
      let text = this.$router.resolve({
        path: "/news/newsInformation/weekNumber",
        query: {
          newsData: data.siteName,
          newsDataId: data.id,
          personType: 1,
        },
      });
      window.open(text.href, "_blank");
    },
    // 单个删除
    deleteTalent(id) {
      this.deleteTalentData[0] = id;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示").then(
        async () => {
          const res = await newsSourceDeleteById(this.deleteTalentData);
          if (res.code == 200) {
            this.getSchemes();
            this.deleteTalentData = [];
            this.$message.success("删除成功");
          }
        }
      );
    },
    // 批量删除
    multDelete() {
      if (this.multipleSelection.length > 1) {
        this.$confirm("此操作将永久删除批量文件, 是否继续?", "提示").then(
          async () => {
            const res = await newsSourceDeleteById(this.multipleSelection);
            if (res.code == 200) {
              this.getSchemes();
              this.multipleSelection = [];
              this.$message.success("删除成功");
            }
          }
        );
      } else {
        this.$message.error("批量删除必须大于一条");
      }
    },
    addItem() {
      this.$router.push({
        name: "SourceInfo",
        query: {
          personCode: "",
        },
      });
    },

    // 正序
    caretTop() {
      this.queryInfo.order = 1;
      this.getSchemes();
    },
    // 倒序
    caretBottom() {
      this.queryInfo.order = 0;
      this.getSchemes();
    },
    formatString(val) {
      if (!val) {
        return [];
      } else {
        return val.split(",");
      }
    },
    formatTime(row, column) {
      const date = new Date(row[column.property]);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    sortChange(column) {
      this.pageNum = 1;
      if (column.prop === "level") {
        this.queryInfo.columnName = "level";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      } else if (column.prop === "recruitmentCount") {
        this.queryInfo.columnName = "recruitment_count";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      } else if (column.prop === "todayAddNum") {
        this.queryInfo.columnName = "today_add_num";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      } else if (column.prop === "outputNum") {
        this.queryInfo.columnName = "output_num";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      }
      this.getSchemes();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-card__body {
  padding: 10px;
}
// 下半el-card部分
.left {
  display: flex;
  align-items: center;
  // position: relative;
  .upload_time {
    position: relative;
    color: #1f212b;
    font-size: 14px;
    margin-left: 20px;
    .el-icon-caret-top,
    .el-icon-caret-bottom {
      position: absolute;
      right: -16px;
      color: #1f212b;
    }
    .el-icon-caret-top {
      top: 0px;
    }
    .el-icon-caret-bottom {
      top: 6px;
    }
  }
}
/deep/ .el-input-group__append {
  background: #1e9fff;
  color: #fff;
  border: 1px solid #1e9fff;
}
.el-dropdown-menu {
  text-align: center;
  border: 1px solid #dbdbdb;
  width: 176px;
  height: 74px;
}
/deep/ .upload {
  padding: 12px 20px;
}
/deep/ .el-dialog__header {
  text-align: center;
  font-weight: 700;
}
.caretTime {
  cursor: pointer;
}
.isActiveTime1 {
  color: #1e9fff !important;
}
.iconColor {
  color: #c0c4cc !important;
}
</style>
